.featureImage {
    overflow: hidden;
    max-height: 20vh;
}

.featureImage > img {
    width: 100%;
    
}

.features {
    padding:40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.feature {
    width: 30%;
    margin-bottom: 40px;
}

.feature img {
    width: 100%;
}