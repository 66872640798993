body {
  font-family: 'Montserrat', Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #F7F7F7;
  color: #333333;
}

.header {
  background-color: #FF8BAF;
  padding: 20px;
  text-align: center;
}

h1, h2, h3 {
  color: #333333;
  font-family: 'Montserrat';
}

.parallax {
  height: 600px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
}

.parallaxOne {
  background-image: url('/public/oranges.jpg');
}

.parallaxTwo {
  background-image: url('/public/held-oranges-tape.jpg');
}

.parallax-content {
  text-align: center;
  color: #333333;
  padding: 0 20px;
  background-color: rgba(255, 139, 175, .6);
}


.footer {
  background-color: #333333;
  color: #333333;
  padding: 10px;
  text-align: center;
}

